import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

export default defineNuxtPlugin({
  name: 'i18n',
  setup: async ({ vueApp }) => {
    const siteConfig = useSiteConfig()
    const localeOverride = useLocaleOverride()

    const i18n = createI18n({
      legacy: false,
      globalInjection: true,
      warnHtmlMessage: false, // Disable the warning for HTML in messages
      locale: localeOverride.value || siteConfig.locale,
      messages,
    })

    vueApp.use(i18n)

    return {
      provide: {
        t: i18n.global.t,
        tm: i18n.global.tm,
        te: i18n.global.te,
      },
    }
  },
})
